'use client';

import { useAppSelector } from '@/Hooks/redux';
import style from './Tags.module.sass';

import { useDispatch } from 'react-redux';
import Link from 'next/link';
import { useEffect } from 'react';
import { getProperty } from '@/Redux/saga/getPostProperty';

export const Tags = () => {
	//получение размера шрифта
	const dispatch = useDispatch();

	const { data }: any = useAppSelector(state => state.getPostProperty);
	const { CurrentFontSize } = useAppSelector(state => state.HeaderState);

	return (
		<div className={style.tagOptions}>
			{data !== undefined ? (
				data?.tags?.map((el: any) => {
					if (el.visible === true && el.type === 'MAIN') {
						return (
							<button className={style.TagsBtn} key={el.id}>
								<Link
									href={`/tag/${el.id}`}
									style={{ fontSize: `${14 + CurrentFontSize}px` }}
									className={style.Link}
								>
									{el.name}
								</Link>
							</button>
						);
					}
				})
			) : (
				<></>
			)}
		</div>
	);
};
